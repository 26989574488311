import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Layout } from '@/shared/components';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import { Navbar } from '../components/Navbar';
import { SignIn } from '../components/Onboard/Steps/SignIn';
import { useStateChainAccount } from '../hooks/useStateChainAccount';
import Hero from '../public/bg-circular-gradient.svg';

export default function Index(): JSX.Element {
  const router = useRouter();
  const { selectedAccount } = usePolkadot();
  const { account } = useStateChainAccount();

  useEffect(() => {
    if (account) {
      if (account.role === 'broker') {
        router.push('/dashboard');
      } else {
        router.push('/onboard');
      }
    }
  }, [account]);

  return (
    <Layout navbar={<Navbar />}>
      <div className="flex grow flex-col items-center justify-center">
        <Hero className="pointer-events-none absolute top-[-80px] hidden h-full md:block" />
        {selectedAccount !== null ? <LoadingSpinner /> : <SignIn />}
      </div>
    </Layout>
  );
}
